<template>
    <div>
        <el-card class="management-report-card-box">
            <el-form v-loading="$waiting.is('loading-newCard')">
                <div class="flex flex-row justify-between items-center">
                    <div class="flex flex-row items-center w-full">
                        <h2 v-if="useNotesNumber" class="text-base font-semibold pl-2 w-20">Not {{ localNum }}</h2>
                        <el-input type="text" :disabled="localInputDisabled" v-model="localInputText.headTitle" class="break-normal text-base font-semibold pl-2" placeholder="Titel" />
                    </div>
                    <div class="flex items-center ml-2">
                        <el-button title="Flytta upp" icon="el-icon-arrow-up" circle @click="moveCardUp()" />
                        <el-button title="Flytta ned" icon="el-icon-arrow-down" circle @click="moveCardDown()" />
                        <el-button size="mini" :disabled="localInputDisabled" :title="tableButtonTitle" @click="toggleTable" type="warning">
                            <i class="fa-regular fa-table-list pr-1" />
                            <i v-if="inputText.addTable == false" class="fa-solid fa-plus" />
                            <i v-else class="fa-solid fa-minus" />
                        </el-button>
                        <el-button title="Radera" type="danger" icon="el-icon-delete" circle @click="deleteCard()" :disabled="localInputDisabled" />
                    </div>
                </div>
                <el-table v-if="inputText.addTable" :data="inputText.tableRows">
                    <el-table-column width="480">
                        <template slot-scope="{ row }">
                            <el-input v-model="row.columnOne" :disabled="localInputDisabled" placeholder="Text" />
                        </template>
                    </el-table-column>
                    <el-table-column>
                        <template slot-scope="{ row }">
                            <el-input v-model="row.columnTwo" :disabled="localInputDisabled" placeholder="Text" />
                        </template>
                    </el-table-column>
                    <el-table-column>
                        <template slot-scope="{ $index, store, row }">
                            <template v-if="store.states.data.length !== 1">
                                <el-button plain size="mini" @click="deleteRowTableList(row)" :disabled="localInputDisabled">
                                    <i class="fa-solid fa-minus" />
                                </el-button>
                            </template>
                            <template v-if="$index === store.states.data.length - 1">
                                <el-button plain size="mini" @click="addRowTableList" :disabled="localInputDisabled">
                                    <i class="fa-solid fa-plus" />
                                </el-button>
                            </template>
                        </template>
                    </el-table-column>
                </el-table>
                <div v-if="toggleValue">
                    <el-input type="textarea" :disabled="localInputDisabled" v-model="localInputText.text" class="break-normal custom-textarea pl-2 pr-2 pt-4" placeholder="Text" />
                </div>
            </el-form>
            <div v-if="useNotesNumber" class="flex flex-row justify-between items-end pt-4">
                <NoteNumberOptions
                    :notes-number="localNum"
                    @update:notesNumber="localNum = $event"
                    :result-note-numbers-list="localResultNoteNumbersList"
                    @update:resultNoteNumbersList="localResultNoteNumbersList = $event"
                    :selected-result-note-numbers="localSelectedResultNoteNumbers"
                    @update:selectedResultNoteNumbers="localSelectedResultNoteNumbers = $event"
                    :balance-note-numbers-list="localBalanceNoteNumbersList"
                    @update:balanceNoteNumbersList="localBalanceNoteNumbersList = $event"
                    :selected-balance-note-numbers="localSelectedBalanceNoteNumbers"
                    @update:selectedBalanceNoteNumbers="localSelectedBalanceNoteNumbers = $event"
                    :input-disabled="localInputDisabled"
                />
                <CardEditButtons :toggle-value="toggleValue" :show-edit-button="showEditButton" :toggle-edit="toggleEdit" :close-modal="closeModal" :save-data="saveData" />
            </div>
            <CardEditButtons v-else :toggle-value="toggleValue" :show-edit-button="showEditButton" :toggle-edit="toggleEdit" :close-modal="closeModal" :save-data="saveData" />
        </el-card>
    </div>
</template>
<script>
export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        inputText: {
            type: Object,
            default: () => ({}),
        },
        tooltipInfo: {
            type: String,
            default: "",
        },
        toggleValue: {
            type: Boolean,
            default: true,
        },
        inputDisabled: {
            type: Boolean,
            default: true,
        },
        useNotesNumber: {
            type: Boolean,
            default: false,
        },
        notesData: {
            type: Object,
            default: () => ({}),
        },
        data: {
            type: Object,
            default: () => ({}),
        },
    },

    data() {
        return {
            selectedYear: null,
            financialYearId: null,
            annualReportId: undefined,
            localInputDisabled: this.inputDisabled,
            showEditButton: true,
            localToggleValue: this.toggleValue,
            localInputText: this.inputText,
            initialInputText: "",
            isInitialInputTextSet: false,
            localNum: this.data.notesNumber,
            localSelectedResultNoteNumbers: this.notesData.selectedResultNoteNumbers,
            localResultNoteNumbersList: [],
            localSelectedBalanceNoteNumbers: this.notesData.selectedBalanceNoteNumbers,
            localBalanceNoteNumbersList: [],
        };
    },
    components: {
        CardEditButtons: () => import("../../managementStatement/components/CardEditButtons.vue"),
        NoteNumberOptions: () => import("../../notes/components/NoteNumberOptions.vue"),
    },
    async created() {
        this.localResultNoteNumbersList = this.notesData.incomeStatementNotesNumbers.map(item => ({
            ...item,
            disabled: false,
        }));
        this.getSelectedList(this.localResultNoteNumbersList, this.localSelectedResultNoteNumbers);

        this.localBalanceNoteNumbersList = this.notesData.balanceNotesNumbers.map(item => ({
            ...item,
            disabled: false,
        }));
        this.getSelectedList(this.localBalanceNoteNumbersList, this.localSelectedBalanceNoteNumbers);
    },
    watch: {
        inputText(newVal) {
            this.localInputText = newVal;
            if (!this.isInitialInputTextSet) {
                this.initialInputText = newVal;
                this.isInitialInputTextSet = true;
            }
        },
        localInputText(newVal) {
            this.$emit("update:text", newVal);
        },
        toggleValue(newVal) {
            this.localToggleValue = newVal;
        },
    },
    methods: {
        toggleEdit() {
            this.localInputDisabled = !this.localInputDisabled;
            this.showEditButton = !this.showEditButton;
        },
        async saveData() {
            this.inputText = this.localInputText;
            this.isInitialInputTextSet = false;

            this.data.notesNumber = this.localNum;
            this.notesData.selectedResultNoteNumbers = this.localSelectedResultNoteNumbers.flat();
            this.notesData.selectedBalanceNoteNumbers = this.localSelectedBalanceNoteNumbers.flat();
            this.updateNoteNumbers();

            this.$emit("saveData");
            this.toggleEdit();
            this.$notify.success({ title: "Sparat " + this.inputText.headTitle });
        },
        updateNoteNumbers() {
            this.localResultNoteNumbersList.forEach(item => {
                if (this.localSelectedResultNoteNumbers.includes(item.value)) {
                    item.notesNumber = this.localNum;
                }
            });
            const newSelectedResultNoteNumbers = this.localResultNoteNumbersList.map(item => ({
                label: item.label,
                notesNumber: item.notesNumber,
                value: item.value,
            }));
            this.notesData.incomeStatementNotesNumbers = newSelectedResultNoteNumbers;

            this.localBalanceNoteNumbersList.forEach(item => {
                if (this.localSelectedBalanceNoteNumbers.includes(item.value)) {
                    item.notesNumber = this.localNum;
                }
            });
            const newSelectedBalanceNoteNumbers = this.localBalanceNoteNumbersList.map(item => ({
                label: item.label,
                notesNumber: item.notesNumber,
                value: item.value,
            }));
            this.notesData.balanceNotesNumbers = newSelectedBalanceNoteNumbers;
        },
        getSelectedList(list, selectedList) {
            list.forEach(row => {
                this.disableRows(row);
                if (this.localNum === row.notesNumber && !selectedList.includes(row.value)) {
                    selectedList.push(row.value);
                }
            });
        },
        disableRows(row) {
            const isInCard = row.notesNumber === this.localNum || row.notesNumber === 0;
            if (isInCard) {
                row.disabled = false;
            } else {
                row.disabled = true;
            }
        },
        closeModal() {
            this.$emit("close");
            const load = "loading-newCard";
            this.$emit("close", { load: load, inputTextNewValue: this.inputTextNewValue });
            this.toggleEdit();
        },
        deleteCard() {
            this.$emit("delete");
            this.$emit("saveData");
        },
        deleteRowTableList(row) {
            const tableRows = this.inputText.tableRows;
            if (tableRows.length > 0) {
                const index = tableRows.indexOf(row);
                if (index > -1) {
                    tableRows.splice(index, 1);
                }
            }
        },
        addRowTableList() {
            const newObj = {
                columnOne: "",
                columnTwo: "",
            };
            this.inputText.tableRows.push(newObj);
        },
        toggleTable() {
            this.inputText.addTable = !this.inputText.addTable;
        },
        moveCardUp() {
            this.$emit("move-up");
            this.$emit("saveData");
        },
        moveCardDown() {
            this.$emit("move-down");
            this.$emit("saveData");
        },
    },
    computed: {
        inputTextNewValue() {
            return this.initialInputText != this.localInputText;
        },
        tableButtonTitle() {
            return this.inputText.addTable == true ? "Ta bort tabell" : "Lägg till tabell";
        },
    },
};
</script>
